<template>
  <div class="SalesTax">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->
          <HeaderMenu />


          <div class="container mt-2 mb-4">
          <div class="row">
            <div class="col-12 card mt-2 ms-0 mx-1 bg-white p-0 border-0 bdr-radi Small shadow">
              <a href="9-sales-tax-monthly-list.php">
          <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-70 img-alt-radi">
                <div class="fn-black ps-4 pt-2 posi-abs">
                  <h1 class="pt-2 fw-bold">GSTR 3B</h1>
                  <h2>GSTR-3B படிவம்</h2>
          </div>
          </a>
          </div>


          <div class="col-12 card mt-2 ms-0 mx-1 bg-white p-0 border-0 bdr-radi Small shadow">
          <a href="9-sales-tax-monthly-list.php">
          <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-70 img-alt-radi">
            <div class="fn-black ps-4 pt-2 posi-abs">
              <h1 class="pt-2 fw-bold">GSTR 1</h1>
              <h2>GSTR-3B படிவம்</h2>
          </div>
          </a>
          </div>


          <div class="col-12 card mt-2 ms-0 mx-1 bg-white p-0 border-0 bdr-radi Small shadow">
          <a href="9-sales-tax-monthly-list.php">
          <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-70 img-alt-radi">
            <div class="fn-black ps-4 pt-2 posi-abs">
              <h1 class="pt-2 fw-bold">GSTR 4</h1>
              <h2>GSTR-4 படிவம்</h2>
          </div>
          </a>
          </div>

          <div class="col-12 card mt-2 ms-0 mx-1 bg-white p-0 border-0 bdr-radi Small shadow">
          <a href="9-sales-tax-monthly-list.php">
          <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-70 img-alt-radi">
            <div class="fn-black ps-4 pt-2 posi-abs">
              <h1 class="pt-2 fw-bold">Payment Receipt</h1>
              <h2>கட்டண சீட்டு்</h2>
          </div>
          </a>
          </div>

          <div class="col-12 card mt-2 ms-0 mx-1 bg-white p-0 border-0 bdr-radi Small shadow">
          <a href="9-sales-tax-monthly-list.php">
          <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-70 img-alt-radi">
            <div class="fn-black ps-4 pt-2 posi-abs">
              <h1 class="pt-2 fw-bold">GSTR Notice / Reply</h1>
              <h2>GSTR அறிவிப்பு / பதில்</h2>
          </div>
          </a>
          </div>

          </div>
          </div>
         <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'
import HeaderMenu from '../include/HeaderMenu.vue';

export default {
  name: 'SalesTax',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  components: {
    HeaderMenu
  },
  methods: {

     //goPageMainMenu() { this.$router.push({ path: '/mainmenu' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
