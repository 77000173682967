import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';


/****** GLOBAL BASE URL *********/
import axios from "axios";
//axios.defaults.baseURL = 'https://psmtrader.com/codi/index.php/api';
axios.defaults.baseURL = 'http://localhost:8080/VUEJS/vuejs-tremmeandous/codi-back/index.php/';
axios.defaults.headers.common = {
  "Content-Type": "application/json"
}
/****** END GLOBAL BASE URL *********/



const app = createApp(App);
app.use(router);
app.mount("#app");
