<template>
  <div class="NextScreen2">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->

          <img src="assets/images/wing.png" class="img-fluid" />
                <div class="col-12 position-center">
              <div class="mt-neg-70"><img src="assets/images/tax.png" class="img-fluid hgt-230" /></div>
              </div>


                <div class="position-center">
                  <h1 class="h3 fw-normal">Income Tax</h1>
                  <h2 class="h3 fw-normal">வருமான வரி</h2>
                    <p>An income tax is a tax imposed on individuals or entities (taxpayers) in respect of the income or profits earned by them</p>

                    <p>வருமான வரி என்பது தனிநபர்கள் அல்லது நிறுவனங்கள் (வரி செலுத்துவோர்) மீது விதிக்கப்படும் வரி அவர்கள் ஈட்டிய வருமானம் அல்லது லாபத்தின் மரியாதை</p>
                </div>
                 <div class="text-center">
                <button class="w-70 btn btn-lg btn-primary mb-2" @click="goPageMainMenu()">Next</button>
              </div>
            <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'NextScreen2',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  methods: {

     goPageMainMenu() { this.$router.push({ path: '/mainmenu' });
     }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
