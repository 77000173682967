<template>
  <div class="MainMenu">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->
          <HeaderMenu />

          <div id="carouselExampleSlidesOnly" class="carousel slide mb-1" data-bs-ride="carousel">
            <div class="carousel-inner coursal-img-size Small shadow txt-bdr-radi border border-5">
            <div class="carousel-item active">
            <img src="assets/images/banner/audit.jpg" class="d-block w-100 coursal-img-size" alt="..." >
            </div>
            <div class="carousel-item">
            <img src="assets/images/banner/audit1.jpg" class="d-block w-100 coursal-img-size" alt="...">
            </div>
            <div class="carousel-item">
            <img src="assets/images/banner/audit2.jpg" class="d-block w-100 coursal-img-size" alt="...">
            </div>
            </div>
            </div>


            <div class="container mb-3">
            <h1 class="h3 fw-bold mt-3 ms-0">Main menu</h1>
            <div class="row">

            <div class="col-6 card width-48 mt-2 ms-0 mx-2 bg-white p-0 border-0 Small shadow">

            <a @click="goPageGallery()">
            <img src="assets/images/menu/menu1.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
              <h1 class="pt-1">Gallery</h1>
              <h2>அலுவலக <br> படங்கள்</h2>
            </div>
            </a>
            </div>


            <div class="col-6 card width-48 mt-2 ms-1 mx-0 bg-white p-0 border-0 Small shadow">

            <a @click="goPageIdCopies()">

            <img src="assets/images/menu/menu2.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
            <h1 class="pt-1">ID Copies</h1>
            <h2>அடையாள <br> நகல்கள்</h2>
            </div>
            </a>
            </div>

            <div class="col-6 card width-48 mt-2 ms-0 mx-2 bg-white p-0 border-0 Small shadow">
            <a @click="goPageSalesTax()">
            <img src="assets/images/menu/menu3.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
            <h1 class="pt-1">Sales Tax</h1>
            <h2>விற்பனை <br> வரி</h2>
            </div>
            </a>
            </div>


            <div class="col-6 card width-48 mt-2 ms-1 mx-0 bg-white p-0 border-0 Small shadow">

            <a @click="goPageIncomeTaxYear()">

            <img src="assets/images/menu/menu4.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
            <h1 class="pt-1">Income Tax</h1>
            <h2>வருமான வரி</h2>
            </div>
            </a>
            </div>


            <div class="col-6 card width-48 mt-2 ms-0 mx-2 bg-white p-0 border-0 Small shadow">

            <a @click="goPageLoanRenewals()">
            <img src="assets/images/menu/menu5.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
            <h1 class="pt-1">New Loan & <br> Renewals</h1>
            <h2>புதிய கடன</h2>
            </div>
            </a>
            </div>

            <div class="col-6 card width-48 mt-2 ms-1 mx-0 bg-white p-0 border-0 Small shadow">
            <a href="">
            <img src="assets/images/menu/menu6.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
            <h1 class="pt-1">Business <br> Ideas</h1>
            <h2>வணிக <br> யோசனைகள்</h2>
            </div>
            </a>
            </div>

            <div class="col-6 card width-48 mt-2 ms-0 mx-2 bg-white p-0 border-0 Small shadow">
            <a href="">
            <img src="assets/images/menu/menu7.png" class="img-fluid img-right hgt-125">
            <div class="fn-black ps-2 pt-1 posi-abs">
            <h1 class="pt-1">Request</h1>
            <h2>கோரிக்கை</h2>
            </div>
            </a>
            </div>




            </div>
            </div>
            <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'
import HeaderMenu from './include/HeaderMenu.vue';


export default {
  name: 'MainMenu',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  components: {
    HeaderMenu
  },
  methods: {


    goPageGallery() { this.$router.push({ path: '/gallery' }); },
    goPageIdCopies() { this.$router.push({ path: '/idcopies' }); },
    goPageSalesTax() { this.$router.push({ path: '/salestax' }); },
    goPageIncomeTaxYear() { this.$router.push({ path: '/incometaxyear' }); },
    goPageLoanRenewals() { this.$router.push({ path: '/loanrenewals' }); },


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
