<template>
  <div class="LoginPin">

    <div class="container">
    <div class="row">

    <div class="col-md-1 col-lg-4"></div>
    <div class="col-sm-12 col-md-10 col-lg-4">

      <main class="form-signin w-100 m-auto">

              <form action="2-next1.php">
                <a href=""><img src="assets/images/splash-logo.png" class="img-fluid mt-100" /></a>
                <h1 class="h3 mt-3 mb-3 fw-normal">Enter 4 Digit Login Pin</h1>

                <div class="form-floating">
                  <input type="number" class="form-control mb-3" id="floatingInput" placeholder="****">
                  <label for="floatingInput">Enter Your Pin</label>
                </div>

                <button class="w-100 btn btn-lg btn-primary" type="submit" @click="goPageSplashscreen()">log in</button>
              </form>

    </main>

  </div>
  <div class="col-md-1 col-lg-4"></div>

  </div><!--row-->
  </div><!--container-->

  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'LoginPin',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  methods: {

    //Splash Screen
    goPageSplashscreen() {
      /*
      this.$router
          .push({ path: '/dashboardadmin' })
          .then(() => { this.$router.go() });
      */
      this.$router.push({ path: '/splashscreen' });


    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
