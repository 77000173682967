<template>
  <div class="IncomeTaxYear">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->
          <HeaderMenu />



          <div class="container">
            <div class="row">
              <div class="col-12 mt-3">
                <h1 class="fw-bold">YEAR</h1>
                <hr style="height:2px;border-width:0;color:gray;background-color:white">
              </div>


                    <div class="col-12 mt-3 ms-0 mx-1 bg-white p-0 bdr-radi card border-0 Regular shadow">

                      <a href="13-income-tax-detail.php">
                  <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-50 img-alt-radi">
                        <div class="fn-black ps-4 pt-2 posi-abs">
                      <h1 class="pt-2 fw-bold">2021-2022</h1>

                  </div>
                 </a>
                </div>


                <div class="col-12 mt-3 ms-0 mx-1 bg-white p-0 bdr-radi card border-0 Regular shadow">

                  <a href="13-income-tax-detail.php">
              <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-50 img-alt-radi">
                    <div class="fn-black ps-4 pt-2 posi-abs">
                  <h1 class="pt-2 fw-bold">2020-2021</h1>

              </div>
             </a>
            </div>


            <div class="col-12 mt-3 ms-0 mx-1 bg-white p-0 bdr-radi card border-0 Regular shadow">

              <a href="13-income-tax-detail.php">
            <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-50 img-alt-radi">
                <div class="fn-black ps-4 pt-2 posi-abs">
              <h1 class="pt-2 fw-bold">2019-2020</h1>

            </div>
            </a>
            </div>

            <div class="col-12 mt-3 ms-0 mx-1 bg-white p-0 bdr-radi card border-0 Regular shadow">

              <a href="13-income-tax-detail.php">
            <img src="assets/images/menu/design.png" class="img-fluid img-right hgt-50 img-alt-radi">
                <div class="fn-black ps-4 pt-2 posi-abs">
              <h1 class="pt-2 fw-bold">2018-2019</h1>

            </div>
            </a>
            </div>

          </div>
          </div>

         <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'
import HeaderMenu from '../include/HeaderMenu.vue';

export default {
  name: 'IncomeTaxYear',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  components: {
    HeaderMenu
  },
  methods: {

     //goPageMainMenu() { this.$router.push({ path: '/mainmenu' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
