<template>
  <div class="SplashScreen">

    <a @click="goPageNextscreen1()"><img src="assets/images/splash-logo-main.png" class="img-fluid mt-200" /></a>

  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'SplashScreen',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  methods: {

    //Splash Screen
    goPageNextscreen1() { this.$router.push({ path: '/nextscreen1' }); }

  },
  created() {



            //Redirect to Next Page
            /*
            this.interval = setInterval(
                function () {
                    this.$router.push({path: "/nextscreen1"});
                }.bind(this),1000
            );
            */

            /*
            //global.jQuery = require('jquery');
            //var $ = global.jQuery;
               setInterval(function() {
                this.$router.push({path: "/nextscreen1"});
              }, 1000);
              */
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
