<template>
  <div class="GalleryList">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->
          <HeaderMenu />

          <div class="container mb-3">
            <div class="row">
          <div class="col-8 mt-3">
              <h1 class="fw-bold">Profile Pic</h1>
              <img src="assets/images/profiles/profilepic.png" class="img-fluid" alt="...">
              <a href="assets/images/profiles/profilepic.png" download>
              <img src="assets/images/download.png" class="img-fluid img-right bottomrgt posi-rlt" alt="" width="30%"></a>
            </div>
            </div>

            <div class="row">
              <h1 class="fw-bold mt-3">Shop Pics - 2nd Feb 2022</h1>
            <div class="col-6 mt-3">

                <img src="assets/images/storeimages/storeimg1.png" class="img-fluid" alt="...">
                <a href="assets/images/storeimages/storeimg1.png" download>
                <img src="assets/images/download.png" class="img-fluid img-right bottomrgt posi-rlt" alt="" width="30%"></a>
              </div>
              <div class="col-6 mt-3">

                  <img src="assets/images/storeimages/storeimg2.png" class="img-fluid" alt="...">
                  <a href="assets/images/storeimages/storeimg2.png" download>
              <img src="assets/images/download.png" class="img-fluid img-right bottomrgt posi-rlt" alt="" width="30%"></a>
                </div>
                <div class="col-6 mt-3">
                    <img src="assets/images/storeimages/storeimg3.png" class="img-fluid" alt="...">
                    <a href="assets/images/storeimages/storeimg3.png" download>
              <img src="assets/images/download.png" class="img-fluid img-right bottomrgt posi-rlt" alt="" width="30%"></a>
                  </div>
                  <div class="col-6 mt-3">
                      <img src="assets/images/storeimages/storeimg4.png" class="img-fluid" alt="...">
                      <a href="assets/images/storeimages/storeimg3.png" download>
                <img src="assets/images/download.png" class="img-fluid img-right bottomrgt posi-rlt" alt="" width="30%"></a>
                    </div>
          </div>

          </div>

         <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'
import HeaderMenu from './include/HeaderMenu.vue';

export default {
  name: 'GalleryList',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  components: {
    HeaderMenu
  },
  methods: {

     //goPageMainMenu() { this.$router.push({ path: '/mainmenu' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
