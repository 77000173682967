<template>
  <div class="HeaderMenu">


          <!-- Mobile View-->

              <nav class="navbar">

                <div class="left-align-logo">


                <a class="mx-2" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                  </svg>
              </a>


              <div class="offcanvas alter-width offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
              <div class="offcanvas-header menu-bk-img">
              <img src="assets/images/favicon1.png" class="img-fluid offcanvas-title" id="offcanvasExampleLabel" alt="" width="30" height="24">
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <ul class="dropdown-item fw-bold mt-3 text-primary">
                  <li class="mb-1"><a  href="#">Home</a></li>
                  <li class="mb-2"><a  href="#">About</a></li>
                  <li class="mb-2"><a  href="#">Contact</a></li>
                </ul>

              </div>
              </div>

                <a class="navbar-brand" @click="goPageMainMenu()">
                  <img src="assets/images/favicon1.png" class="img-fluid" alt="" width="40" height="40">
                </a>

              </div>

              <a class="navbar-brand" href="4-menu.php">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
              </svg></a>


              </nav>

          <!-- END Mobile View-->


  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'HeaderMenu',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  methods: {

    //Splash Screen
    goPageMainMenu() { this.$router.push({ path: '/mainmenu' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
