import Login from './components/Login.vue';
import SplashScreen from './components/SplashScreen.vue';
import NextScreen1 from './components/NextScreen1.vue';
import NextScreen2 from './components/NextScreen2.vue';
import MainMenu from './components/MainMenu.vue';
import GalleryList from './components/GalleryList.vue';
import IdCopies from './components/idcopies/IdCopies.vue';
import SalesTax from './components/salestax/SalesTax.vue';
import IncomeTaxYear from './components/incometax/IncomeTaxYear.vue';
import LoanRenewals from './components/loanrenewals/LoanRenewals.vue';


import { createRouter, createWebHistory } from "vue-router";


const routes = [
  {
    //name : "Login",
    //component : Login,
    path : "/",
    redirect: '/login'
  },
  {
    name : "Login",
    component : Login,
    path : "/login",
  },
  {
    name : "SplashScreen",
    component : SplashScreen,
    path : "/splashscreen",
  },
  {
    name : "NextScreen1",
    component : NextScreen1,
    path : "/nextscreen1",
  },
  {
    name : "NextScreen2",
    component : NextScreen2,
    path : "/nextscreen2",
  },
  {
    name : "MainMenu",
    component : MainMenu,
    path : "/mainmenu",
  },
  {
    name : "GalleryList",
    component : GalleryList,
    path : "/gallery",
  },
  {
    name : "IdCopies",
    component : IdCopies,
    path : "/idcopies",
  },
  {
    name : "SalesTax",
    component : SalesTax,
    path : "/salestax",
  },
  {
    name : "IncomeTaxYear",
    component : IncomeTaxYear,
    path : "/incometaxyear",
  },
  {
    name : "LoanRenewals",
    component : LoanRenewals,
    path : "/loanrenewals",
  },







];

const router = createRouter({
  history : createWebHistory(),
  routes,
});

export default router;
