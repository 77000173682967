<template>
  <div class="NextScreen1">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->

            <img src="assets/images/wing.png" class="img-fluid"/>
            <div class="col-12 position-center">
            <div class="mt-neg-70"><img src="assets/images/sale.png" class="img-fluid hgt-250"/></div>
          </div>


              <div class="position-center">
                <h1 class="h3 fw-normal">Sales Tax</h1>
                <h2 class="h3 fw-normal">விற்பனை வரி</h2>
                <p>A sales tax is tax paid to a governing body for the sales of certain goods and services.</p>
                <p>விற்பனை வரி என்பது சில பொருட்கள் மற்றும் சேவைகளின் விற்பனைக்காக ஆளும்
                  குழுவிற்கு செலுத்தப்படும் வரி</p>
              </div>
               <div class="text-center">
              <button class="w-70 btn btn-lg btn-primary mb-2" type="" @click="goPageNextscreen2()">Next</button>
            </div>
            <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'NextScreen1',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  methods: {

    //Splash Screen
    goPageNextscreen2() { this.$router.push({ path: '/nextscreen2' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
