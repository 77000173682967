<template>
  <div class="IdCopies">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->
          <HeaderMenu />


            <div class="container mb-3 mt-3">
             <div class="row">
               <div class="col-12 p-2 bg-white p-0 bdr-radi Regular shadow">
                 <a href="7-id-copies-detail.php">
                   <div class="fn-black">
                     <div class="row">
                       <div class="col-4">
                     <img src="assets/images/id/pancard.png" class="img-fluid hgt-70">
                     </div>
                     <div class="col-5">
                     <h1 class="mt-3 ">PAN Card</h1>
                     <h2 class="mt-2">பான் கார்டு்</h2>
                   </div>
                 <div class="col-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" class="bi bi-check-circle img-right mt-3" viewBox="0 0 16 16">
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                     <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                   </svg>
                 </div>
               </div>
             </div>
             </a>
             </div>
             </div>
            </div>

            <div class="container mb-3 mt-3">
             <div class="row">
               <div class="col-12 p-2 bg-white p-0 bdr-radi Regular shadow">
                 <a href="7-id-copies-detail.php">
                   <div class="fn-black">
                     <div class="row">
                       <div class="col-4">
                     <img src="assets/images/id/aadhar.png" class="img-fluid hgt-70">
                     </div>
                     <div class="col-5">
                     <h1 class="mt-3 ">Aadhar Card</h1>
                     <h2 class="mt-2">ஆதார் அட்டை</h2>
                   </div>
                 <div class="col-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" class="bi bi-check-circle img-right mt-3" viewBox="0 0 16 16">
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                     <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                   </svg>
                 </div>
               </div>
             </div>
             </a>
             </div>
             </div>
            </div>

            <div class="container mb-3 mt-3">
             <div class="row">
               <div class="col-12 p-2 bg-white p-0 bdr-radi Regular shadow">
                 <a href="7-id-copies-detail.php">
                   <div class="fn-black">
                     <div class="row">
                       <div class="col-4">
                     <img src="assets/images/id/licence.png" class="img-fluid hgt-70">
                     </div>
                     <div class="col-5">
                     <h1 class="mt-3 ">Driving Licence</h1>
                     <h2 class="mt-2">ஓட்டுனர் உரிமம்</h2>
                   </div>
                 <div class="col-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" class="bi bi-check-circle img-right mt-3" viewBox="0 0 16 16">
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                     <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                   </svg>
                 </div>
               </div>
             </div>
             </a>
             </div>
             </div>
            </div>

            <div class="container mb-3 mt-3">
             <div class="row">
               <div class="col-12 p-2 bg-white p-0 bdr-radi Regular shadow">
                 <a href="7-id-copies-detail.php">
                   <div class="fn-black">
                     <div class="row">
                       <div class="col-4">
                     <img src="assets/images/id/vote.png" class="img-fluid hgt-70">
                     </div>
                     <div class="col-5">
                     <h1 class="mt-3 ">Voter ID</h1>
                     <h2 class="mt-2">வாக்காளர் அட்டை</h2>
                   </div>
                 <div class="col-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" class="bi bi-check-circle img-right mt-3" viewBox="0 0 16 16">
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                     <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                   </svg>
                 </div>
               </div>
             </div>
             </a>
             </div>
             </div>
            </div>

            <div class="container mb-3 mt-3">
             <div class="row">
               <div class="col-12 p-2 bg-white p-0 bdr-radi Regular shadow">
                 <a href="7-id-copies-detail.php">
                   <div class="fn-black">
                     <div class="row">
                       <div class="col-4">
                     <img src="assets/images/id/passport.png" class="img-fluid hgt-70">
                     </div>
                     <div class="col-5">
                     <h1 class="mt-3 ">Passport</h1>
                     <h2 class="mt-2">கடவுச்சீட்டு</h2>
                   </div>
                 <div class="col-3">
                   <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" class="bi bi-check-circle img-right mt-3" viewBox="0 0 16 16">
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                     <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                   </svg>
                 </div>
               </div>
             </div>
             </a>
             </div>
             </div>
            </div>
         <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'
import HeaderMenu from '../include/HeaderMenu.vue';

export default {
  name: 'IdCopies',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  components: {
    HeaderMenu
  },
  methods: {

     //goPageMainMenu() { this.$router.push({ path: '/mainmenu' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
