<template>
  <div class="LoanRenewals">


        <div class="container">
        <div class="row">

        <div class="col-md-1 col-lg-4"></div>
        <div class="col-sm-12 col-md-10 col-lg-4">

          <!-- Mobile View-->
          <HeaderMenu />



            <div class="container mt-5">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active text-black" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Create</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link text-black" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">List</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <form action="">

                <div class="form-floating mt-3">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                    <option selected disabled>Choose Your Bank</option>
                    <option value="1">SBI Bank</option>
                    <option value="2">ICICI Bank</option>
                    <option value="3">AXIS Bank</option>
                  </select>
                  <label for="floatingSelect">Bank</label>
                </div>

                        <div class="col-12 mt-3">
                            <label for="">Type</label>
                        </div>


                        <div class="form-check form-check-inline loan-checkbox mt-3">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                          <label class="form-check-label" for="inlineRadio1">New Loan</label>
                        </div>

                        <div class="form-check form-check-inline loan-checkbox ">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                          <label class="form-check-label" for="inlineRadio2">Renewal</label>
                        </div>

                        <div class="form-floating">
                          <input type="text" class="form-control mt-3" id="floatingInput" placeholder="1 Year">
                          <label for="floatingInput">Projection</label>
                        </div>

                        <div class="form-floating">
                          <input type="text" class="form-control mt-3" id="floatingInput" placeholder="OD">
                          <label for="floatingInput">Loan Type</label>
                        </div>

                        <div class="form-floating">
                          <textarea type="text" class="form-control mt-3" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                          <label for="floatingTextarea2">Comments</label>
                        </div>

                        <div class="form-floating">
                          <input type="text" class="form-control mt-3" id="floatingInput" placeholder="2,00,000">
                          <label for="floatingInput">Amount</label>
                        </div>

                      <div class="form-floating">
                        <input type="text" class="form-control mt-3" id="floatingInput" placeholder="2,000">
                        <label for="floatingInput">Rate Of Interest</label>
                      </div>


                    <div class="d-grid gap-2 mt-4 mb-5">
                      <button class="btn btn-primary" type="button">Submit</button>
                    </div>

                  </form></div>
              <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">

                <div class="col-12 card bg-white pb-1 mt-3 txt-bdr-radi Small shadow border-0">
                        <a href="15-list-loan-renewals-detail.php" class="fn-black">
                          <div class=" col-7 fn-black ms-3 pt-1 posi-abs">
                            <h1 class="pt-1 fw-bold">ICICI Bank</h1>
                            <h2 class="fw-bold mb-1">Type: New loan</h2>
                            <h3 class="fw-bold mb-1">Projection: 1 Years</h3>
                            <h4 class="fw-bold mb-0">Loan Type: Loan</h4>

                      </div>

                          <div class="fn-black mx-2 px-3 pt-1 text-end">
                            <h1 class="fw-bold">Amount</h1>
                            <h1 class="fw-bolder text-info mb-0">Rs.20,000</h1>
                            <h2 class="mb-0 mt-1">Rate of Interest</h2>
                            <p class="fw-bold">Rs.2,000</p>
                      </div>
                      <p class="fw-light fn-black ms-3 mb-0 mt-2">Submitted on: 28th jun 2022-10:46 AM</p>
                    </a>
                  </div>


            <div class="col-12 card bg-white pb-1 mt-3 txt-bdr-radi Small shadow border-0">
                    <a href="15-list-loan-renewals-detail.php" class="fn-black">
                      <div class=" col-7 fn-black ms-3 pt-1 posi-abs">
                        <h1 class="pt-1 fw-bold">HDFC Bank</h1>
                        <h2 class="fw-bold mb-1">Type: New loan</h2>
                        <h3 class="fw-bold mb-1">Projection: 1 Years</h3>
                        <h4 class="fw-bold mb-0">Loan Type: Vechile Loan</h4>

                  </div>

                      <div class="fn-black mx-2 px-3 pt-1 text-end">
                        <h1 class="fw-bold">Amount</h1>
                        <h1 class="fw-bolder text-info mb-0">Rs.90,000</h1>
                        <h2 class="mb-0 mt-1">Rate of Interest</h2>
                        <p class="fw-bold">Rs.2,000</p>
                  </div>
                  <p class="fw-light fn-black ms-3 mb-0 mt-2">Submitted on: 28th march 2022-10:46 AM</p>
                </a>
              </div>


              <div class="col-12 card bg-white pb-1 mt-3 txt-bdr-radi Small shadow border-0">
                      <a href="15-list-loan-renewals-detail.php" class="fn-black">
                        <div class=" col-7 fn-black ms-3 pt-1 posi-abs">
                          <h1 class="pt-1 fw-bold">RBI Bank</h1>
                          <h2 class="fw-bold mb-1">Type: New loan</h2>
                          <h3 class="fw-bold mb-1">Projection: 1 Years</h3>
                          <h4 class="fw-bold mb-0">Loan Type: Home Loan</h4>

                    </div>

                        <div class="fn-black mx-2 px-3 pt-1 text-end">
                          <h1 class="fw-bold">Amount</h1>
                          <h1 class="fw-bolder text-info mb-0">Rs.49,00,000</h1>
                          <h2 class="mb-0 mt-1">Rate of Interest</h2>
                          <p class="fw-bold">Rs.20,000</p>
                    </div>
                    <p class="fw-light fn-black ms-3 mb-0 mt-2">Submitted on: 28th jan 2022-10:46 AM</p>
                  </a>
                </div>

              </div>
            </div>
            </div>



         <!-- END Mobile View-->


      </div>
      <div class="col-md-1 col-lg-4"></div>

      </div><!--row-->
      </div><!--container-->


  </div>
</template>

<script>
//import axios from 'axios'
import HeaderMenu from '../include/HeaderMenu.vue';

export default {
  name: 'LoanRenewals',
  data() {
    return {


    }
  },
  props: {
    msg: String,
  },
  components: {
    HeaderMenu
  },
  methods: {

     //goPageMainMenu() { this.$router.push({ path: '/mainmenu' }); }


  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
